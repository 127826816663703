import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import Logo from './Logo';
import Navigation from './Navigation';

import { installExtensionAction } from '../utils/install';

import "../assets/css/header.css";

// Import the SVG image
// import downloadIcon from '../assets/img/download-button.svg';

const Header = () => {
  const iconStyle = {
    width: '30px',
    height: '30px',
  };

  const { pathname } = useLocation(); // Get the current path
  const showInstallButton = false;
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    // Update the document title based on the current path
    switch (pathname) {
      case '/':
        setActivePage(0);
        break;
      case '/versions':
        setActivePage(1);
        break;
      case '/contact':
        setActivePage(2);
        break;
    }
  }, [pathname]); // Re-render the effect whenever the pathname changes

  const handleInstallClick = async () => {
    await installExtensionAction("site-header-button");
  };

  return (
    <header>
      <div className='container-fluid notify-block'>
        <div className="row justify-c-c text-center notify-message-block">
          <div>
            <h2>Плагін зупиняє свою роботу назавжди...</h2>
            <span style={{fontSize: "13px"}}>Через скаргу, подану до Google власником одного з плагінів-конкурентів та, відповідно, тривалу відсутність фінансування наші сервери невдовзі припинять свою роботу.</span>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="row justify-c-sb">
          <div className='row'>
            <Logo />
            <Navigation />
          </div>
          <div className='col'>
            {showInstallButton && ( // Conditionally render the button
              <button
                disabled={!showInstallButton} // Disable button if not shown
                className="download-button"
                title="Встановити розширення"
                onClick={handleInstallClick}
              >
                {/* <img src={downloadIcon} width={30} alt="Встановити плагін"   /> */}
                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" className="download-icon" style={iconStyle} xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="green" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="green" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
